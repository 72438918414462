import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Link from 'gatsby-link';
import get from 'lodash/get';

import Header from "../components/Header";
import Layout from "../components/layout";

class Work extends React.Component {
    render() {
        const posts = get(this, 'props.data.allMarkdownRemark.edges')

        var createGroupedArray = function(arr, chunkSize) {
            var groups = [], i;
            for (i = 0; i < arr.length; i += chunkSize) {
                groups.push(arr.slice(i, i + chunkSize));
            }
            return groups;
        }
        var groupedPosts = createGroupedArray(posts, 3);

        return (
          <Layout>
            <Helmet title={"Other Work | Rob Allgood Design"} />
            <svg className="top green" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1366 603">
               <path class="st0" d="M1366,183c-80,0-178-131-336-131S822,0,822,0h544V183z"/>
            </svg>
            <Header />
            <div className="container content">
                {groupedPosts.map((array, index) => {
                    return(
                        <div className="row" key={index}>
                            {array.map(({ node }, index) => {
                                return(
                                    <div key={index} className="col">
                                        <div className="hover-box">
                                            <img src={node.frontmatter.photo.childImageSharp.fluid.src} className="demo-image" />
                                            <div className="hover-layer">
                                                <div className="hover-text text-center">
                                                    <h4>{node.frontmatter.client}</h4><br />&mdash;<br /><p>{node.frontmatter.type}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
          </Layout>
        )
    }
}

export default Work

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            client
            photo {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            type
          }
        }
      }
    }
  }
`
